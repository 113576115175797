<template>
  <v-container>
    <validation-observer ref="observer">
      <form class="p-2" @submit.prevent="submit">
        <v-row>
          <v-col cols="12">
            <h4>New Type</h4>
          </v-col>
          <v-col cols="12">
            <validation-provider v-slot="{ errors }" name="Name" rules="required">
              <v-text-field
                v-model="aircraft_types.name"
                :error-messages="errors"
                label="Name"
                required
                outlined
                dense
                :prepend-inner-icon="icons.mdiAccountOutline"
              >
              </v-text-field>
            </validation-provider>
          </v-col>
        </v-row>

        <v-btn type="submit" color="primary" class="ma-10 pa-5">
          Save
        </v-btn>
      </form>
    </validation-observer>
  </v-container>
</template>

<script>
import { required } from "vee-validate/dist/rules";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import { mdiAccountOutline, mdiEmailOutline, mdiCellphone, mdiLockOutline } from "@mdi/js";
import util from "@/core/utils/misc";
import Request from "@/core/api/request";

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

export default {
  name: "AircraftTypeAdd",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      aircraft_types: {},
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCellphone,
        mdiLockOutline,
      },
    };
  },
  methods: {
    updateBrownse() {
      this.$emit("updateBrownse");
    },
    async submit() {
      if (this.$refs.observer.validate()) {
        util.isLoading({ active: true, title: "Wait while we process." });
        await Request.post(`${this.$IYA.URI.AIRCRAFT_TYPES__ADD}`, this.aircraft_types).then(response => {
          if (response.status === this.$IYA.HTTP.OK) {
            this.$swal(`Done !`, `Action accomplished`, `success`);
            this.updateBrownse();
          }
        });
        util.isLoading();
      }
    },
  },
};
</script>
